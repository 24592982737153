// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/favicon.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7gdrRLoP5nhNkW4-Fer5wg\\=\\= {\n    aspect-ratio: 1/1;\n    height:100%;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n    background-size: 100%;\n    display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/styles/homeButton.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,oEAA4D;IAC5D,qBAAqB;IACrB,qBAAqB;AACzB","sourcesContent":[".homeButton {\n    aspect-ratio: 1/1;\n    height:100%;\n    background: url('../../assets/favicon.png') no-repeat center;\n    background-size: 100%;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeButton": "_7gdrRLoP5nhNkW4-Fer5wg=="
};
export default ___CSS_LOADER_EXPORT___;
