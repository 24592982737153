// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/search_icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qn80n-LseDGcQzz\\+p16ouA\\=\\= {\n   display: flex;\n}\n\n.qn80n-LseDGcQzz\\+p16ouA\\=\\= input[type=text] {\n    padding: 8px;\n    border-radius: 4px;\n    border: 2px solid lightgrey;\n    outline: none;\n    transition: border .125s linear;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 10px center;\n    background-size: 20px 20px;\n    padding-left: 35px;\n    background-color: white;\n    flex-grow: 1;\n\n}\n\n.qn80n-LseDGcQzz\\+p16ouA\\=\\= input[type=text]:focus {\n    border: 2px solid rgb(54, 54, 54);\n}", "",{"version":3,"sources":["webpack://./src/styles/searchBar.module.css"],"names":[],"mappings":"AAAA;GACG,aAAa;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,aAAa;IACb,+BAA+B;IAC/B,yEAAqE;IACrE,0BAA0B;IAC1B,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;;AAEhB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".searchBar {\n   display: flex;\n}\n\n.searchBar input[type=text] {\n    padding: 8px;\n    border-radius: 4px;\n    border: 2px solid lightgrey;\n    outline: none;\n    transition: border .125s linear;\n    background: url('../../assets/search_icon.svg') no-repeat 10px center;\n    background-size: 20px 20px;\n    padding-left: 35px;\n    background-color: white;\n    flex-grow: 1;\n\n}\n\n.searchBar input[type=text]:focus {\n    border: 2px solid rgb(54, 54, 54);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": "qn80n-LseDGcQzz+p16ouA=="
};
export default ___CSS_LOADER_EXPORT___;
