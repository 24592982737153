// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* CSS for about and bot pages */\n\n.w2eompsPQunj2qbO9odu7w\\=\\= {\n    background-color: white;\n    border-style: solid;\n    border: black;\n    border: solid;\n    border-color: black;\n    border-radius: 10px;\n    padding-left: 10px;\n    padding-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/styles/staticPage.module.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["/* CSS for about and bot pages */\n\n.textBody {\n    background-color: white;\n    border-style: solid;\n    border: black;\n    border: solid;\n    border-color: black;\n    border-radius: 10px;\n    padding-left: 10px;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBody": "w2eompsPQunj2qbO9odu7w=="
};
export default ___CSS_LOADER_EXPORT___;
