// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NZqfbwBq4qJ3tQH79kdR2Q\\=\\=>div:not(:last-child) {\n    margin-bottom: .75em;\n}", "",{"version":3,"sources":["webpack://./src/styles/resultList.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB","sourcesContent":[".resultList>div:not(:last-child) {\n    margin-bottom: .75em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultList": "NZqfbwBq4qJ3tQH79kdR2Q=="
};
export default ___CSS_LOADER_EXPORT___;
