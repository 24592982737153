// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t5M83bOJaLli5uSxg03k2w\\=\\= {\n    text-decoration: none;\n    color: black;\n    font-size: larger;\n    margin-top: 1.5vh;\n}\n\n.t5M83bOJaLli5uSxg03k2w\\=\\=:last-child {\n    text-align: right;\n}\n\n.t5M83bOJaLli5uSxg03k2w\\=\\= a:visited {\n    color: black;\n}\n\n.t5M83bOJaLli5uSxg03k2w\\=\\=:hover {\n    text-decoration: underline;\n}\n\n.WiYE1zxG-6x3xjJj7e3wOA\\=\\= {\n    margin: 5px;\n    display: flex;\n    /* flex-grow: 1; */\n    /* justify-content: space-between; */\n    height: 6vh;\n}\n\n.WiYE1zxG-6x3xjJj7e3wOA\\=\\= * {\n    flex-basis: 0;\n    flex-grow: 1;\n}\n\n.G6wNZYYY5Zns18W\\+SD6FNw\\=\\= {\n    flex-grow: 0;\n}\n\n.NFZGGgqKYW4OLv3hLeq2UQ\\=\\= {\n    margin-bottom: 1.25em;\n}", "",{"version":3,"sources":["webpack://./src/styles/rulePage.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,oCAAoC;IACpC,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".navLink {\n    text-decoration: none;\n    color: black;\n    font-size: larger;\n    margin-top: 1.5vh;\n}\n\n.navLink:last-child {\n    text-align: right;\n}\n\n.navLink a:visited {\n    color: black;\n}\n\n.navLink:hover {\n    text-decoration: underline;\n}\n\n.navLinkContainer {\n    margin: 5px;\n    display: flex;\n    /* flex-grow: 1; */\n    /* justify-content: space-between; */\n    height: 6vh;\n}\n\n.navLinkContainer * {\n    flex-basis: 0;\n    flex-grow: 1;\n}\n\n.navLinkHome {\n    flex-grow: 0;\n}\n\n.rulePage {\n    margin-bottom: 1.25em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navLink": "t5M83bOJaLli5uSxg03k2w==",
	"navLinkContainer": "WiYE1zxG-6x3xjJj7e3wOA==",
	"navLinkHome": "G6wNZYYY5Zns18W+SD6FNw==",
	"rulePage": "NFZGGgqKYW4OLv3hLeq2UQ=="
};
export default ___CSS_LOADER_EXPORT___;
